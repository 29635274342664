<template>
    <navigation-mobile-pos title="Bestellen" :minimal="true" />

    <div class="px-2 flex-1 flex flex-col mobile-content-wrapper" v-cloak>
        <!-- <loading-indicator v-if="!initialized" class="mt-64" /> -->

        <div class="py-4 gap-3 overflow-auto scrollbar-hide" v-if="groups">
            <div class="flex justify-start" v-for="(groupRow, gIndex) in groupRows" :key="gIndex">
              <div class="" v-for="(group, index) in groupRow" :key="index">
                  <div @click="selectGroup(group)" class="group p-2 cursor-pointer overflow-hidden flex items-center justify-center" :class="{'border-b-2': selected_group.id == group.id }" :style="{'border-color': selected_group.id == group.id ? group.color : ''}">
                      <div class="truncate font-semibold">{{ group.name }}</div>
                  </div>
                  <div class="menu-border"></div>
              </div>
            </div>
        </div>


        <div class="overflow-auto scrollbar-hide flex-grow">
            <div class=" grid grid-cols-3 gap-1" v-if="groups">
                <item :initialitem="item" view="mobile" :parentcolor="selected_group.color" :initialquantity="quantity" @itemadded="handleItemAdded()" v-for="(item, index_items) in selected_group.items" :key="index_items" />
            </div>
        </div>

        <div class="py-2">
            <div class="grid grid-cols-2 gap-3">
                <div class="col-span-1" v-if="terminal.extra_basket_functionalities_position == 'quick_topup'">
                   <div class="h-full button-sjefgreen-outline flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm"
                        @click="$refs.walletaddcredit.open()">
                            <span class="text-white">
                                Kaart opladen
                            </span>
                        </div>
                </div>
                <div class="col-span-1" v-if="terminal.extra_basket_functionalities_position == 'quick_topup'">
                   <div class="h-full button-sjefgreen-outline flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm"
                        @click="$refs.walletcheckcredit.open()">
                            <span class="text-white">
                                Kaart saldo
                            </span>
                        </div>
                </div>
                <div class="col-span-1"  v-if="paymenttype">
                    <div @click="transactionClear()" class="h-full button-sjefgreen-outline flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm">
                        <svg class="text-white h-5 w-full" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"/>
                        </svg>
                    </div>
                </div>
                <div class="col-span-1">
                    <router-link :to="{name: 'mobilepos.receipt'}" class="h-full button-sjefgreen-outline  flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm">
                        <span class="text-white">{{$t('translations.views.mobilepos.pages.direct.bon')}}</span>
                    </router-link>
                </div>

                <div class="col-span-1">
                    <div @click="gotoTransaction()" class="h-full button-sjefgreen-outline flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm">
                        <span class="text-white">{{$t('translations.views.mobilepos.pages.direct.alternatief')}}</span>
                    </div>
                </div>
                <div class="col-span-1" v-if="paymenttype">
                    <div @click="gotoTransaction(paymenttype.id)" class="h-full button-sjefgreen flex items-center justify-center col-span-2 cursor-pointer p-2 mt-1 overflow-hidden rounded-sm">
                        {{vueNumberFormat(transaction.total) }} {{ paymenttype.attributes.name }}
                    </div>
                </div>
            </div>
        </div>

           <modal-wallet-add-credit ref="walletaddcredit"
            @success="this.$router.push({ name: 'mobilepos.receipt' });"
            :allow-scanner="true" />

            <modal-wallet-check-credit ref="walletcheckcredit"
            :allow-scanner="true" />
    </div>

</template>

<script>
// import axios from 'axios'

// components
// import LoadingIndicator from '../../../components/LoadingIndicator'
import NavigationMobilePos from '../../../components/NavigationMobilePos'
import ModalWalletAddCredit from '../../../components/ModalWalletAddCredit.vue';
import ModalWalletCheckCredit from '../../../components/ModalWalletCheckCredit.vue';

// mixins
import PosBootstrapHelper from '../../../mixins/pos-bootstrap.helper';
import PosHelper from '../../../mixins/pos.helper.js';


export default {
    name: 'mobilepos.direct',
    mixins: [PosBootstrapHelper, PosHelper],
    components: {
        // LoadingIndicator,
        ModalWalletAddCredit,
        ModalWalletCheckCredit,
        NavigationMobilePos,
    },
    data () {
        return {
            initialized: false,
        }
    },
    mounted() {
        this.groups = []
        this.getPerferredPaymenttype()
        this.getItems()
    },

    methods: {
        handleItemAdded() {
        },
    },
    computed: {
      terminal() {
        return this.$store.getters['terminal/terminal'];
      },
      groupRows () {
        if (this.groups.length <= 6) return [this.groups]
        const mid = Math.ceil(this.groups.length / 2)
        return [this.groups.slice(0, mid), this.groups.slice(mid)]
      },
    },
}
</script>
